html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
}
body {
    margin: 0;
    line-height: inherit;
}
hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
}
abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}
h1, h2, h3, h4 {
    font-family: 'Arial';
    text-transform: uppercase;
}
h1 strong, h2 strong, h3 strong, h4 strong {
    font-family: 'Arial Black';
    letter-spacing: 6px;
}
h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.5em;
}
h3 {
    font-size: 1.17em;
}
summary h3 {
    display: inline-block;
    font-size: 1.17em;
    margin: 0px;
}
h4 {
    font-size: 1em;
}
a {
    color: inherit;
    text-decoration: inherit;
}
b,
strong {
    font-weight: bolder;
}
code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-size: 1em;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}
button,
select {
    text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    background-color: #fff0;
    background-image: none;
}
:-moz-focusring {
    outline: auto;
}
:-moz-ui-invalid {
    box-shadow: none;
}
progress {
    vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}
summary {
    display: list-item;
}
fieldset {
    margin: 0;
    padding: 0;
}
legend {
    padding: 0;
}
ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
dialog {
    padding: 0;
}
textarea {
    resize: vertical;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af;
}
input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
}
button,
[role="button"] {
    cursor: pointer;
}
:disabled {
    cursor: default;
}
