.content {
    background-color: #f5f8fd;
}
.content-page {
    padding: 0 1em 3em 1em;
}
p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
}
.content-blockquote {
    background-color: #b0c2e6;
    padding: 1em;
}
blockquote {
    color: #000;
    font-size: 1em;
}
blockquote h1 {
    font-size: 2rem;
}
blockquote p {
    font-style: italic;
    margin-bottom: 0;
}
blockquote p::before,
blockquote p::after {
    content: "“";
    font-family: Georgia;
    font-size: 6rem;
    margin: -2.5rem -2rem 0 -3rem;
    position: absolute;
    opacity: 0.5;
}
blockquote p::after {
    content: "”";
    margin: -1.75rem 0 0 0;
}
blockquote cite {
    font-size: 0.75rem;
}
ol {
    list-style: none;
    counter-reset: item;
}
li {
    counter-increment: item;
    margin-bottom: 5px;
}
li:before {
    margin-right: 10px;
    content: counter(item);
    background: #3370a9;
    border-radius: 100%;
    color: #fff;
    width: 1.5em;
    text-align: center;
    display: inline-block;
}
ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
}
ul.header li:before {
    content: "";
    position: none;
    left: 0;
}
ul.header {
    background-color: #111;
    padding: 0;
}
ul.header li a {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    padding: 20px 5px;
    display: inline-block;
}
.content-row {
    background-color: #b0c2e6;
    width: 100%;
    height: 200px;
    max-height: 240px;
    overflow: hidden;
    margin: 0;
    position: relative;
}
.logo-row {
    background-color: #000;
    width: 100%;
    height: 200px;
    max-height: 240px;
    overflow: hidden;
    margin: 0;
    position: relative;
}
.logo {
    width: 30%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.logo-img {
    width: 20%;
}
.rudy-img {
    width: 40%;
    border-radius: 50%;
}
.rudy {
    background-image: url('./rudy-sumardi.jpg');
    height: 100vh;
    background-position: center;
    background-attachment: fixed;
    /* background-position: 100% 100%; */
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-size: auto 170vh;
}
.footer {
    background-color: #3074b3;
    width: 100%;
    height: 200px;
    max-height: 240px;
    overflow: hidden;
    margin: 0;
    position: relative;
}
.pass {
    width: 20%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.H2 {
    width: 50%;
    height: auto;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.H21 {
    width: 30%;
    height: auto;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: table;
}
.container2 {
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: table;
}
.left {
    display: table-cell;
    width: 50%;
    text-align: center;
}
.right {
    margin: 0;
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.center {
    position: absolute;
    left: 50%;
    display: inline;
    width: auto;
    transform: translateX(-50%);
}
@media only screen and (max-width: 480px) {
    .container {
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: table;
        height: 110vh;
    }
    .container2 {
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: table;
        height: 70vh;
    }
    .logo {
        width: 50%;
    }
    .logo-img {
        width: 40%;
    }
    .rudy-img {
        width: 60%;
        border-radius: 50%;
    }
    .rudy {
        background-image: url('./rudy-sumardi.jpg');
        height: 50vh;
        background-position: center top;
        background-attachment: initial;
        /* background-position: 100% 100%; */
        background-repeat: no-repeat;
        /* background-size: cover; */
        background-size: 120vw auto;
    }
    .pass {
        width: 35%;
    }
    .H2 {
        width: 90%;
        height: auto;
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .H21 {
        width: 60%;
        height: auto;
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .right {
        margin: 0;
        position: absolute;
        top: 0;
        transform: translateY(0);
    }
}
.video {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
}
#player {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
}
.pasta {
    max-width: 100%;
    padding: 1rem;
    position: relative;
    background: linear-gradient(to right, rgb(135, 193, 255), rgb(136, 238, 136));
    padding: 3px;
}
.module {
    background: #f5f8fd;
    color: black;
    padding: 2rem;
    text-align: center;
}
center {
    margin:3em 0;
}

details {
    margin: 0px 0 5px 0;
}
details > * {
    padding: .75rem;
}
details > div {
    background: #000;
    border-radius: 0 0 5px 5px;
    color: #fff;
}
summary {
    border-radius: 5px;
    color: #fff;
    background: #3074b3;
    cursor: pointer;
}
details[open] summary {
    border-radius: 5px 5px 0 0;
}

details > summary {
    list-style-type: none;
}

details > summary::-webkit-details-marker {
    display: none;
}

details > summary::before {
    content: '▶️ ';
}

details[open] > summary::before {
    content: '🔽 ';
}